<template>
  <div class="row">
    <div class="col-12 col-md-6 col-sm-6 col-lg-6 offset-sm-3 offset-lg-3 mt-10 mb-10">
      <div class="card">
        <div class="card-body rounded bg-gray-site">
          <div class="row">
            <div class="text-center">
              <img width="112" class="my-7 mb-5" src="media/icons/iconsax/calendar-clock.svg" alt="calendar" />

              <h1 class="fs-1 fw-bold mb-5">Tu cita fue agendada exitosamente</h1>
              <p class="fs-base">
                Tu cita para la revisión de tu vehículo será<br>
                <span class="text-blue fs-base">{{ schedule.dia[0] }} a las {{ schedule.horario[0] }}</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12 text-center pb-2">
              <img width="60" class="my-5" src="media/logos/logo-solana.svg" alt="logo" />
              <br>
              <h2 class="fs-5 fw-bold">{{ vehicle.brand }} | {{ vehicle.model }} | {{ vehicle.version }} | {{ vehicle.year }}</h2>
            </div>
          </div>

          <div class="row" v-if="appointment.tipo == '3'" >
            <div class="col-md-12 col-12 pb-2 text-center d-flex justify-content-center align-items-center my-2">
              <div class="justify-content-center align-items-center d-flex w-40px h-40px w-md-50px h-md-50px rounded-3 bg-gray-lighten">
                <i class="isax-location text-white fs-2"></i>
              </div>
            </div>

            <div class="col-md-12 col-12 pb-2 text-center">
              <h2 class="fs-5 fw-bold">Dirección</h2>
              <p class="fs-base text-gray">
                {{ schedule.ubicacion.address }} {{ schedule.ubicacion.num_ext }}<br> 
                {{ schedule.ubicacion.colonia }} {{ schedule.ubicacion.cp }}<br> 
                {{ schedule.ubicacion.state }}, {{ schedule.ubicacion.city }}
              </p>
            </div>
          </div>
          
          <!-- <div class="row" v-if="appointment.tipo == '1'">
            <div class="col-md-12 col-12 text-center pb-5">
              <p class="fs-base text-gray">Te hemos enviado un correo con las instrucciones y <br> documentación a tener para agilizar el trámite.</p>
            </div>
          </div> -->

          <div v-if="appointment.tipo == '1'">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-8 col-lg-8 offset-md-2 offset-lg-2 rounded">
                <div ref="mapDiv" class="rounded" style="height:250px"></div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 text-center mt-10">
                <div class="d-flex justify-content-center">
                  
                  <div class="float-start inline-logo">
                    <img width="40" src="media/icons/iconsax/shop.svg" alt="logo"/>
                  </div>

                  <div class="float-start">
                    <h2 class="fs-5 fw-bold">{{ agency.name }}</h2> 
                    <p class="fs-base text-gray">
                      <span>{{ agency.address }}, {{ agency.colonia }}</span><br>
                      <span>{{ agency.city }}, {{ agency.state }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 text-center">
                
                <a target="_blank" :href="schedule.agency.url_whatsapp">
                  <button class="btn btn-success ">
                    <inline-svg class="w-20px h-20px" src="media/icons/iconsax/Whatsapp.svg" /> 
                    <span class="fs-base"> Contactar por Whatsapp</span> 
                  </button>
                </a>

                <!-- <button class="btn btn-success">
                  <inline-svg class="w-20px h-20px" src="media/icons/iconsax/Whatsapp.svg" /> 
                  <span class="fs-base"> Contactar por Whatsapp</span> 
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
  import { computed, defineComponent, onMounted, ref, onBeforeMount, watch } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import axios from "axios";
  import ApiService from "@/core/services/ApiService";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import { Loader } from  '@googlemaps/js-api-loader';

  // const API_KEY="AIzaSyCfMvfhTrHVnq2ZbbR8-4l7ANzT6PQXYq8";
  const API_KEY="AIzaSyACDXm5WBEngJZHLoEiSzzYCdlkg3THQmI";

  export default {
    name: "AgencyAppointment",
    components: {

    },
    setup(props) {
      const route = useRoute();
      const router = useRouter();

      const loader = new Loader({ apiKey:API_KEY });
      const mapDiv = ref(null);
      const map = ref();

      const vehicle = ref({
        id: "",
        brand:"",
        model:"",
        version:"",
        year:"",
      });

      const customer = ref({
        Email: "",
        Names: "",
        Phone: "",
        Surnames: "",
        City: "",
        Address: "",
      });

      const agency = ref({
        id: 1,
        name: "Grupo Solana",
        id_state: 26,
        city: "Hermosillo",
        address: "Opalo",
        colonia: "Colonia 1",
        cp: "34434",
        state: "Sonora",
        url_whatsapp: "wa.link/mzrjt5",
        phone: "2323324334",
        email: "Solana@grupo.com",
        latitude: "19.45427274236404",
        longitude: "-99.13482926287841"
      });

      const appointment = ref({
        tipo: 1,
        fecha: "",
        nombre:"",
        apellidos:"",
        email:"",
        phone:"",
        vehicle_client:"",
        agency:"",
        agency_id:"",
      });
      
      const schedule = ref({
        tipo_cita: {
            id: 1,
            name: "Avalúo en agencia"
        },
        status: {
            id: 1,
            name: "Pendiente"
        },
        dia: [
            "lunes agosto 1º 2022"
        ],
        horario: [
            "12:00 p. m. - 1:00 p. m."
        ],
        vehicle: {
            vehiculo: "Audi Q5 3.0 V6 SQ5 Tiptronic At 2019",
            kms: "33600",
            year: "2020",
            brand: "Hyundai",
            model: "Tucson",
            version: "GLS",
        },
        precio_cotizado: {
            price: "718535.00"
        },
        cliente: {
            nombre: "Luis Ramos",
            correo: "elcorreo@mail.com",
            telefono: "1234567811",
        },
        ubicacion:{
          address:"Jesus Reyes Heroles 2058, Col",
          city:"Guadalajara",
          colonia:"Balcones",
          cp:"44980",
          entre_calles:"paseo de la serenata 5090",
          num_ext:"80",
          num_int:"S/N",
          state:"Jalisco",
        },
        agency: {
          address: "Prolongación Paseo de la Reforma 11230",
          city: "Guadalajara",
          colonia: "Moderna",
          id: 18,
          latitude: "20.666221549464936",
          longitude: "-103.36156299791213",
          mail: "elcorreo@mail.com",
          name: "Grupo turn",
          phone: "4343434343",
          state: "Jalisco",
          url_whatsapp: "232323",
        },
        created_at: "07/07/2022",
        updated_at: "07/07/2022"
      });

      const getSchedule = async (id) => {
        try {
          const { data } = await axios.get(process.env.VUE_APP_API_URL + "/api/cita/" + id);
          console.log(data);

          if (data.success) {
            schedule.value = data.data;
          }
          
        } catch (error) {
          console.log(error);
        }
      };

      const getAgency = async (id) => {
        try {
          let url = process.env.VUE_APP_API_URL + `/api/site/agencies/${id}`;
          const { data } = await axios.get(url);
          // console.log(data);

          if (data.success) {
            agency.value = data.data.info;
            // console.log(data);
          }
          
        } catch (error) {
          console.log(error);
        }
      };
      
      onBeforeMount(async () => {
        // await getSchedule(route.params.id);
        // agency.value = JSON.parse(localStorage.getItem("appointment_agency") || "{}");
        vehicle.value = JSON.parse(localStorage.getItem("appointment_vehicle") || "{}");
        appointment.value = JSON.parse(localStorage.getItem("appointment_data") || "{}");
        customer.value = JSON.parse(localStorage.getItem("appointment_customer") || "{}");
        schedule.value = JSON.parse(localStorage.getItem("appointment_schedule") || "{}");
        
        if(appointment.value.agency_id){
          await getAgency(appointment.value.agency_id);
        }

        // console.log(schedule.value.ubicacion);
        console.log(schedule.value.agency);

        // localStorage.removeItem("appointment_agency");
        // localStorage.removeItem("appointment_vehicle");
        // localStorage.removeItem("appointment_data");
        // localStorage.removeItem("appointment_customer");
        // localStorage.removeItem("appointment_valuation");
        // localStorage.removeItem("extra_data");
      });

      onMounted(async () => {
        await loader.load();
        // console.log(agency.value);
        
        map.value  = new google.maps.Map(mapDiv.value, {
          center: { lat:0, lng:0 },
          zoom:13,
        });
        
        if(agency.value.latitude && agency.value.longitude){
          map.value.setCenter({ lat:parseFloat(agency.value.latitude), lng:parseFloat(agency.value.longitude) });
          
          new google.maps.Marker({
            position: { lat:parseFloat(agency.value.latitude), lng:parseFloat(agency.value.longitude) },
            map: map.value,
            title: agency.value.nombre,
            icon: {	
              scaledSize: new google.maps.Size(32, 32),
							url:"media/icons/iconsax/location-red.png",
            },
          });
        }
      });
      
      return {
        mapDiv,
        schedule,
        getAgency,
        getSchedule,
        agency,
        vehicle,
        customer,
        appointment,
      };
    },
  };
</script>

<style lang="scss">
  .container-table {
    height:150px;
    border:1px solid white;
  }
  .vertical-center-row {
    margin:auto;
    width:60%;
    text-align:center;
  }
</style>